<template>
  <LazyPartnersEntryPageTariffs />
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

const cityStore = useCities()
const mainStore = useMainStore()
const newRes = ref()
const currentCity = computed(() => cityStore.getCity)
const addressFromCookie = useCookie('currentAddress')
const wrongAddress = computed(() => mainStore.getWrongAddress)
onBeforeMount(async () => {
  addressFromCookie.value = null
  mainStore.$patch({
    wrongAddress: true,
  })
  try {
    newRes.value = await $fetch(`${mainStore.apiDomain}/locations/address-objects/${currentCity.value?.fias_id}/ancestors/`, {
      method: 'GET',

      params: {
        include_self: true,
        morph: true,
        site_id: import.meta.env.VITE_SITE_ID,
      },
    })
  }
  catch {}
})

onBeforeUnmount(() => {
  if (wrongAddress.value) {
    cityStore.$patch({
      addressAncestors: newRes.value,
    })
  }
})
</script>

<style scoped lang="scss"></style>
